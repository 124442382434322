<template>
  <div class="home">
    <div class="container">
      <signin v-if="authorized==0" />
      <VideoFeature v-if="VideoFeature && (authorized==1 ||  authorized==3)" :videosfeature="VideoFeature"/>
      <div class="row"  v-if="authorized && (authorized==2 )" style="background-color: brown;">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="card card-stats">
                <div class="card-header card-header-warning card-header-icon">
                  <h3 class="card-title">
                    <small style="color:black">Limited access. You have no permission</small>
                  </h3>
                </div>
              </div>
              </div>
       </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
}
</style>

<script>
import { mapMutations, mapActions } from "vuex";
import VideoFeature from '@/components/FeatureVideos.vue'
import Signin from './Signin.vue';

export default {
  name: 'Feature',
  data() {
    return {
      VideoFeature: false,
    }
  },
  components: {
    VideoFeature,
    Signin
  },
 
  
  computed: {
    authorized() {
       return this.$store.getters['getAuhtState'];
    },
  },
  mounted() {
    this.doAuthorize();
    this.getVideosFeature()
  },
  methods: {
    ...mapActions(["doAuthorize"]),
    ...mapMutations([
      "setError",
      "setMsg"
    ]),
    getVideosFeature() {
      fetch('/api/videos/featured', {
        method: 'GET'
      })
          .then(async r => {
            let response = await r.json()
            if (response.error) {
              this.setError(response)

              return
            }
            this.setMsg('Groups, Videos obtained');

            this.VideoFeature = response;
          })
    }
  }
}
</script>
