<template>
  <div>
      <div class="card-transparent border rounded border-secondary text-white mb-3 group">
        <div class="videos"  v-if="authorized && (authorized==1 ||  authorized==3)">
          <div class="item" v-for="video in videosfeature" :key="video.id" @click="gotoVideo(video.video_id)" :style="`background-image: url(http://img.youtube.com/vi/${video.channel_link}/mqdefault.jpg)`">
            <div class="title">{{video.name}}</div>
          </div>
        </div>
      </div>
      <div class="row"  v-if="authorized && (authorized==2 )" style="background-color: brown;">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="card card-stats">
                <div class="card-header card-header-warning card-header-icon">
                  <h3 class="card-title">
                    <small style="color:black">Limited access. You have no permission</small>
                  </h3>
                </div>
              </div>
              </div>
      </div>
  </div>
</template>

<style lang="less" scoped>
.group {
    .videos {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
}
.item {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  width: calc(33% - 1em);
  height: 210.22px;
  background-size: cover;
  .title {
    position: absolute;
    font-size: 1.2em;
    width: 90%;
    padding: 0.5em;
    text-shadow: 2px 1px 6px black, 2px 1px 6px black;
  }
}
</style>

<script>
import router from '../router';
import { mapMutations , mapActions } from "vuex";
export default {
  name: "VideoFeature",
  props: ["videosfeature"],
  computed: {
    authorized() {
       return this.$store.getters['getAuhtState'];
    },
  },
  mounted() {
    this.doAuthorize();
  },

  methods: {
    ...mapActions(["doAuthorize"]),
    ...mapMutations([
      "setError",
      "setMsg"
    ]),

    gotoVideo(id) {
      router.push({ name: 'Video', params: { videoRouteId: id } })
    }
  }
};
</script>