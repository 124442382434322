<template>
  <div>
    <div class="card-transparent border rounded border-secondary text-white mb-3 group">
      <div class="group">
            <div  v-for="(group, index) in listgroup" style="display: flex; flex-direction: column; position: relative;  cursor: pointer; margin-right: .5em; margin-bottom: .5em; height: auto; background-size: cover;"
                 :key="index" :id="group.group_id"

            >
              <div class="badge badge-primary mb-2"  @click="gotoVideo(group.group_id)">{{group.name}}</div>
              <div class="title">{{group.description}}</div>
              <div class="labels">
                <table>
                  <tr>
                    <td>
                      <div style="min-width:100px">
                      <!-- Стрелка вверх -->
                      <svg class="strelka-top-2" viewBox="0 0 9 14"  @click="move(index,index-1);"  v-if="index!=0">
                        <path class="svg-strelka" d="M6.660,8.922 L6.660,8.922 L2.350,13.408 L0.503,11.486 L4.813,7.000 L0.503,2.515 L2.350,0.592 L8.507,7.000 L6.660,8.922 Z" ></path>
                      </svg>
                        <br>

                      <!-- Стрелка вниз -->
                      <svg class="strelka-bottom-2" viewBox="0 0 9 14" @click="move(index,index+1);" v-if="index!=(listgroup.length-1)">
                        <path class="svg-strelka" d="M6.660,8.922 L6.660,8.922 L2.350,13.408 L0.503,11.486 L4.813,7.000 L0.503,2.515 L2.350,0.592 L8.507,7.000 L6.660,8.922 Z" ></path>
                      </svg>
                      </div>
                    </td>
                    <td>
                      {{group.labels}}
                    </td>
                  </tr>
                </table>

              </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.group {
    .videos {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
}
.item {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  width: calc(33% - 1em);
  height: 210.22px;
  background-size: cover;
  .title {
    position: absolute;
    font-size: 1.2em;
    width: 90%;
    padding: 0.5em;
    text-shadow: 2px 1px 6px black, 2px 1px 6px black;
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
  width:100%;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}


.strelka-left-2,
.strelka-right-2,
.strelka-top-2,
.strelka-bottom-2 {
  //margin: 20px 8px;
  width: 20px;
  height: 20px;
}


.strelka-left-2 path,
.strelka-right-2 path,
.strelka-top-2 path,
.strelka-bottom-2 path {
  fill: #BFE2FF;
  stroke-width: .6;
  stroke: #337AB7;
  transition: fill 0.5s ease-out;
  cursor: pointer;
}
.strelka-left-2 {
  transform: rotate(180deg);
}
.strelka-left-2 {
  margin-left: 314px;
}
.strelka-right-2 {
  margin-left: 17px;
}
.strelka-top-2 {
  transform: rotate(270deg);
}
.strelka-bottom-2 {
  transform: rotate(90deg);
}
.strelka-left-2 path:hover,
.strelka-right-2 path:hover,
.strelka-top-2 path:hover,
.strelka-bottom-2 path:hover {
  fill: #337AB7;
}

</style>

<script>
import router from '../router';
import { mapMutations } from "vuex";
Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  var groups = this.map(function(arr, index) {
    arr.sort_order = index;
    fetch('/api/videosgroup/update', {
      method: 'POST',
      async:false,
      body: JSON.stringify(arr)
    })
        .then(async r => {
          let response = await r.json()
          if (response.error) {
            console.log(response.error)
          }
        })
  });
  console.assert(groups);
  return this;
};
export default {
  name: "ListGroup",
  props: ["listgroup"],
  order: 100,
  data() {
    return {
      group: {
        id: '',
        name:'',
        group_id: '',
        description: '',
        sort_order: 0,
        labels: '',
        videos: ''
      },
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
 
  methods: {
    ...mapMutations([
      "setError",
      "setMsg"
    ]),
    move(from, to) {
      this.listgroup.move(from, to);
    },

    gotoVideo(id) {
      router.push({ name: 'Group', params: { groupRouteId: id } })
    },
    }
};
</script>